class ManageProject {

    //Suggests a key based on either a client or project name
    static topform(component) {
        return [
          {
            id: 1,
            sections: [
              { 
                rows: [
                  {
                    components: [
                      {
                        name: "InputGenericCheckbox",
                        properties: {
                          class: "col-xl-12 px-5 mb-0",
                          id: 'input-published',
                          label: `${component.$t("GENERAL.STATUS.PUBLISHED")}`,
                          model: component.$v.form.published,
                          submissionStates: component.submissionStates
                        }
                      }
                    ]
                  }
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.PROJECT_INFO")}`,
                rows: [
                  {
                    components: [
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-8 px-5",
                          id: 'input-name',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.NAME")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.NAME")}`,
                          model: component.$v.form.name,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.NAME")}`
                        }
                      }
                    ]
                  },
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.PROJECT_LOCALE")}`,
                rows: [
                  {
                    components: [
                      {
                        name: "InputGenericDropdown",
                        properties: {
                          class: "col-xl-4 px-5 mb-5",
                          id: 'input-language-code',
                          label: `${component.$t("FORMS.INPUTS.LANGUAGE")}`,
                          placeholder: `${component.$t("FORMS.PLACEHOLDER.LANGUAGE")}`,
                          model: component.$v.form.system_language_code,
                          options: component.systemLanguages,
                          optionLabel: "name",
                          value: "code",
                          errorMessage: `${component.$t("FORMS.ERROR.LANGUAGE")}`,
                        }
                      },
                      {
                        name: "InputGenericDropdown",
                        properties: {
                          class: "col-xl-4 px-5 mb-5",
                          id: 'input-timezone-code',
                          label: `${component.$t("FORMS.INPUTS.TIMEZONE")}`,
                          placeholder: `${component.$t("FORMS.PLACEHOLDER.TIMEZONE")}`,
                          model: component.$v.form.system_timezone_code,
                          options: component.systemTimezones,
                          optionLabel: "name",
                          value: "code",
                          errorMessage: `${component.$t("FORMS.ERROR.TIMEZONE")}`,
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
    }
  
  }
  
export default ManageProject;