<template>
  <div class="row col-xl-12 mx-0 px-0">
    <!--start::TOPFORM-->
    <Topform
      ref="template-topform"
      managementType="update"
      :status="calculatedStatus"
      pageType="project"
      :name="(loadingComplete) ? projectTemplateInfo.name : null"
      :errors="computedErrors"
      :submissionStates="submissionStates"
      parentPage="/client/projects"
      :tabs="computedTabs"
      :validation="$v.form"
      :loadingComplete="loadingComplete"
      @clearErrors="setProjectTemplateErrors"
      @submit="onSubmit"
    >
    </Topform>
    <!--end::TOPFORM-->
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  mapActions,
  mapGetters,
  mapMutations  
} from "vuex";
import { required, maxLength } from "vuelidate/lib/validators";
import { SET_BREADCRUMB } from "@/core/services/store/system/breadcrumbs.module";
import PageLoader from "@/core/classes/PageLoader.js";

import TopformConfig from "@/core/config/topform/ManageTemplate.js";
import Toast from "@/core/classes/Toast.js";

export default {
  mixins: [validationMixin],

  name: "EditTemplate",

  data() {
    return {
      pageLoader: new PageLoader(1),
      toast: new Toast(),
      submissionStates: {
        submitting: false
      },
      form: {
        published: 1,
        name: "",
        system_language_code: 'eng',
        system_timezone_code: 'UTC'
      },
    }
  },

  validations: {
    form: {
      published: {
        required
      },
      name: {
        required,
        maxLength: maxLength(255)
      },
      system_language_code: {
        required
      },
      system_timezone_code: {
        required
      }
    }
  },

  mounted() {
    let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `${this.$t("NAVIGATION.ROOT_MENU.CLIENTS")}`, route: protocol + 'admin.' + process.env.VUE_APP_API_ROOT + "/#/clients/view" },
      { title: this.clientInfo.name, route: protocol + this.clientInfo.key + ".admin." + process.env.VUE_APP_API_ROOT + "/#/client/projects" },
      { title: `${this.$t("NAVIGATION.TEMPLATE_MENU.EDIT")}` }
    ]);

    this.fetchProjectTemplate();
  },

  methods: {
    fetchProjectTemplate: function() {
      let payload = {
        route: `api/v1/clients/${this.clientInfo.id}/project_templates/${this.$route.params.id}`
      };

      this.$store.dispatch(GENERIC_RESOURCE, payload)  
        .then((res) => {
          this.setProjectTemplateInfo(res);
          this.form.published = this.projectTemplateInfo.published;
          this.form.name = this.projectTemplateInfo.name;
          this.form.system_language_code = this.projectTemplateInfo.system_language_code;
          this.form.system_timezone_code = this.projectTemplateInfo.system_timezone_code;
          this.pageLoader.setCompleteComponent(1);
        }).catch((error) => {
          this.setProjectTemplateErrors(error);
        })
    },
    onSubmit: function() {
      this.setProjectTemplateErrors(null);

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.scrollUp();
        return;
      }

      this.submissionStates.submitting = true;

      let payload = {
        url: `api/v1/clients/${this.clientInfo.id}/project_templates/${this.projectTemplateInfo.id}`,
        data: this.form
      };

      this.updateProjectTemplate(payload)
        .then((res) => {
          this.toast.notify(this, this.form.name + ' ' + `${this.$t("TOASTS.EDIT_TEMPLATE.MESSAGE")}`, `${this.$t("TOASTS.EDIT_TEMPLATE.TITLE")}`, 'success');
        })
        .finally(() => {
          this.scrollUp();
          this.submissionStates.submitting = false;
        });
    },
    scrollUp: function() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    ...mapActions({
      updateProjectTemplate: "updateProjectTemplate"
    }),
    ...mapMutations({
      setProjectTemplateInfo: "setProjectTemplateInfo",
      setProjectTemplateErrors: "setProjectTemplateErrors"
    })
  },

  computed: {
    ...mapGetters([
      "clientInfo",
      "projectTemplateInfo",
      "projectTemplateErrors",
      "systemLanguages",
      "systemTimezones",
    ]),
    loadingComplete: function() {
      return this.pageLoader.getStatus();
    },
    calculatedStatus: function() {
      if (this.loadingComplete == true) {
        return (this.projectTemplateInfo.published) ? "published" : "unpublished";
      } else {
        return null;
      }
    },
    computedTabs: function() {
      return TopformConfig.topform(this);
    },
    computedErrors: function() {
      return (this.projectTemplateErrors == null) ? {} : this.projectTemplateErrors;
    }
  }
}
</script>